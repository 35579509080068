<template>
    <div class="goods">
        <div class="container">
            <my-popup :is-show="isShow" :title="title" right="发券" @leftHandler="toggleShowPopup" @rightHandler="openCouponPopup">
                <div class="content">
                    <div class="goods-item" v-for="(item, index) in list" :key="item.id">
                        <div class="left-serial">{{ index + 1 }}</div>
                        <div class="right-goods-info">
                            <div class="goods-img-box">
                                <img
                                    class="goods-img"
                                    :src="item.goods_info.goods_master_img | goodsImg"
                                    :alt="item.goods_info.goods_name"
                                    :title="item.goods_info.goods_name"
                                />
                                <div class="state-mask-box" v-if="item.maskStatus != 0">
                                    <div class="state" v-if="item.maskStatus == 1">
                                        <i class="icon iconfont">&#xe63c;</i>
                                        <span>讲解中</span>
                                    </div>
                                    <div class="state state-end" v-else-if="item.maskStatus == 2">已讲解</div>
                                </div>
                            </div>
                            <div class="goods-info">
                                <div class="top-info">
                                    <div class="title">{{ item.goods_info.goods_name }}</div>
                                    <div class="coupon-info" v-if="couponMap[item.goods_id] && couponMap[item.goods_id].length > 0">
                                        <template v-for="couponItem in couponMap[item.goods_id]">
                                            <div class="coupon-item" :key="couponItem.id" @click="openCouponPopup(item.goods_id)">
                                                <template v-if="couponItem.type == 1">
                                                    <span class="decorate">券</span>
                                                    <span>
                                                        满{{ parseInt(couponItem.coupon_full) }}减{{
                                                            parseInt(couponItem.coupon_minus)
                                                        }}
                                                    </span>
                                                </template>
                                                <template v-else>
                                                    <span class="decorate">券</span>
                                                    <span
                                                        >满{{ parseInt(couponItem.coupon_full) }}打{{
                                                            parseInt(couponItem.coupon_minus)
                                                        }}折</span
                                                    >
                                                </template>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="bottom-price">
                                    <span class="decorate">￥</span>
                                    <span>{{ item.goods_info.goods_discount_price }}</span>
                                </div>
                            </div>
                            <div class="action">
                                <el-button class="item release" v-if="item.status == 0" @click="release(item)">发布</el-button>
                                <el-button class="item lose" v-else-if="item.status == 1" @click="fall(item)">下架</el-button>
                                <el-button class="item finish" v-else-if="item.status == 2">发布</el-button>
                                <el-button class="item finish" v-else-if="item.status == 3">下架</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </my-popup>
        </div>
    </div>
</template>

<script>
import MyPopup from "./containers/MyPopup";

export default {
    name: "GoodsPopup",
    data() {
        return {};
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
        list: {
            type: Array,
            default: () => [],
        },
        couponList: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        title() {
            return `已选商品 (${this.list.length})`;
        },
        couponMap() {
            const allCoupon = this.couponList.reduce(
                (arr, item) => arr.concat(item.child.map((subItem) => ({ ...subItem, type: item.coupon_type }))),
                []
            );
            const map = {};
            allCoupon.forEach((item) => {
                if (map[item.goods_id] && map[item.goods_id].length < 2) {
                    map[item.goods_id].push(item);
                } else if (!map[item.goods_id]) {
                    map[item.goods_id] = [item];
                }
            });
            return map;
        },
    },
    components: {
        MyPopup,
    },
    filters: {
        goodsImg(src) {
            return `${localStorage.getItem("host_url")}${src}`;
        },
    },
    methods: {
        toggleShowPopup() {
            this.$emit("update:isShow", false);
        },
        openCouponPopup(goodsId = "") {
            this.$emit("openCouponPopup", goodsId);
        },
        release(item) {
            this.$emit("release", { check: "goods", item });
        },
        fall(item) {
            this.$emit("fall", item);
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.goods {
    .container {
        .content {
            display: flex;
            flex-direction: column;
            .goods-item {
                box-sizing: border-box;
                display: flex;
                padding-right: 20px;
                .left-serial {
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 55px;
                    padding: 20px 0;
                    font-family: 缁忓吀缁艰壓浣撶畝;
                    font-size: 18px;
                    color: #1b162a;
                }
                .right-goods-info {
                    display: flex;
                    flex: 1;
                    padding: 20px 0 20px;
                    border-bottom: 1px solid #ddd;
                    overflow: hidden;
                    .goods-img-box {
                        position: relative;
                        width: 115px;
                        height: 115px;
                        vertical-align: middle;
                        .goods-img {
                            width: 100%;
                            height: 100%;
                        }
                        .state-mask-box {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            top: 0;
                            right: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: rgba(0, 0, 0, 0.3);
                            .state {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 80px;
                                height: 30px;
                                color: #fff;
                                font-size: 14px;
                                border-radius: 4px;
                                background-color: #fb0f4b;
                                .icon {
                                    margin-right: 6px;
                                    font-size: 14px;
                                }
                            }
                            .state-end {
                                background-color: #a0a0a0;
                            }
                        }
                    }
                    .goods-info {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        margin: 0 10px 0 20px;
                        overflow: hidden;
                        .top-info {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            overflow: hidden;
                            .title {
                                font-size: 18px;
                                color: #1b162a;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .coupon-info {
                                display: flex;
                                flex-wrap: nowrap;
                                width: 100%;
                                margin-top: 10px;
                                .coupon-item {
                                    margin-right: 10px;
                                    padding: 2px 9px;
                                    font-size: 14px;
                                    color: #fe3266;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    border: 1px solid #fe3266;
                                    border-radius: 4px;
                                    cursor: pointer;
                                    .decorate {
                                        margin-right: 9px;
                                    }
                                }
                                .coupon-item:nth-child(2n) {
                                    margin-right: 0;
                                }
                            }
                        }
                        .bottom-price {
                            font-size: 24px;
                            color: #cb0505;
                            .decorate {
                                font-size: 16px;
                            }
                        }
                    }
                    .action {
                        display: flex;
                        align-items: flex-end;
                        .item {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 90px;
                            height: 36px;
                            color: #fff;
                            background-color: #4b45ff;
                        }
                        .lose {
                            background-color: #12c1c1;
                        }
                        .finish {
                            background-color: #aaa;
                            cursor: default;
                        }
                    }
                }
            }
        }
    }
}
</style>
