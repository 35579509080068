<template>
    <div class="live-pause-modal" v-if="isShow">
        <div class="container">
            <live-modal :is-show="isShow" @update:isShow="cancelConfirm" :confirm-handler="confirmPause">
                暂停直播时，观众将无法看到你的直播画面，单场直播只能暂停3次
            </live-modal>
        </div>
    </div>
</template>

<script>
import LiveModal from "./containers/LiveModal";
import HintDialog from "./containers/HintDialog";
import { clearTimeout, setTimeout } from "timers";

export default {
    name: "LivePause",
    data() {
        return {
            showDialog: true,
        };
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
    },
    computed: {},
    components: {
        LiveModal,
        HintDialog,
    },
    methods: {
        cancelConfirm() {
            this.$emit("update:isShow", false);
        },
        confirmPause() {
            this.showDialog = false;
            this.$emit("live-pause");
        },
    },
    filters: {},
    mounted() {},
};
</script>

<style scoped lang="scss">
.live-pause-modal {
    color: #060111;
}
</style>
